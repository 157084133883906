import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import {ProjectCreate, ProjectLoad, ProjectUpdate} from '../../../../actions/project.actions';
import {getProjects} from '../../../../selectors/project.selector';
import {Project} from '../../../../models/Project';
import {getBuildingTypes} from '../../../../selectors/building-type.selector';
import {BuildingTypesLoad} from '../../../../actions/building-type.actions';
import {BuildingType} from '../../../../models/BuildingType';
import {areProjectRoomtsLoading, getProjectRooms} from '../../../../selectors/project-room.selector';
import {ProjectRoom} from '../../../../models/project-room.model';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {ProjectRoomCreate, ProjectRoomDelete, ProjectRoomLoad, ProjectRoomUpdate} from '../../../../actions/project-room.actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {getProjectRoomTypes} from '../../../../selectors/project-room-type.selector';
import {ProjectRoomType} from '../../../../models/project-room-type.model';
import {getInframeshMountingStyles} from '../../../../selectors/inframesh-mounting-style.selector';
import {InframeshMountingStyle} from '../../../../models/inframesh-mounting-style.model';
import {ProjectRoomTypesLoad} from '../../../../actions/project-room-type.actions';
import {InframeshMountingStylesLoad} from '../../../../actions/inframesh-mounting-style.actions';
import {getOwnUser} from '../../../../selectors/user.selector';
import {User} from '../../../../models/user.model';
import {environment} from '../../../../../environments/environment';
import {OfferOverlayComponent} from './offer-overlay/offer-overlay.component';
import {Partner} from '../../../../models/Partner';
import {pipe} from 'rxjs';
import {getPartnerStateData} from '../../../../selectors/partner.selector';
import {PartnerLoad} from '../../../../actions/partner.actions';
import {OffersForProjectLoad} from '../../../../actions/offer.actions';
import {ProjectRoomDeleteConfirmComponent} from './project-room-delete-confirm/project-room-delete-confirm.component';

@Component({
  selector: 'project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  id: string;
  project: Project;
  buildingTypes: BuildingType[] = [];
  projectRoomTypes: ProjectRoomType[] = [];
  inframeshMountingStyles: InframeshMountingStyle[] = [];

  projectRooms: ProjectRoom[] = [];
  dataSource = null;
  columnsToDisplay = ['name'];

  showCreateProjectRoom = false;
  projectRoomForm: FormGroup;

  editProject = false;
  projectForm: FormGroup;

  isLoading$;
  isCreate = true;
  selectedRoom: ProjectRoom;

  user: User;
  partner: Partner;

  rbc9Sum = 0;
  totalSqm = 0;

  subRooms: ProjectRoom[] = [];

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.buildForm(null);

    this.projectRoomForm = this.formBuilder.group({
      name: ['', Validators.required],
      roomType: [''],
      floor: [0],
      size: [1, Validators.min(0.3)],
      height: [0.5, [Validators.min(0.5), Validators.max(4)]],
      numberWindows: [0],
      windowsInsulated: [true],
      subFloorUnheated: [true],
      supFloorUnheated: [true],
      plasterInsteadCeiling: [false],
      numberOuterWalls: [0, Validators.min(0)],
      numberDoorsInside: [0, Validators.min(0)],
      mountingStyle: [''],
      workByClient: [0],
      hasWellness: [false],
      wellnessSize: [0, Validators.min(0)],
      wellnessMountingStyle: [''],
      hasFussboden: [false],
      fussbodenSize: [0, Validators.min(0)],
      fussbodenMountingStyle: ['']
    });

    this.store.pipe(select(getOwnUser)).subscribe(user => {
      this.user = user;

      this.store.select(pipe(getPartnerStateData)).subscribe(partners => {
        this.partner = partners.find(p => p.id === user.partner);
      });

      this.store.dispatch(new PartnerLoad(user.partner));
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.store.dispatch(new ProjectLoad(this.id));
    });

    this.store.pipe(select(getProjects)).subscribe(projects => {
      if (projects) {
        this.project = projects.find(p => p.id === this.id);
        this.buildForm(this.project);
      }
    });

    this.isLoading$ = this.store.pipe(select(areProjectRoomtsLoading));

    this.store.pipe(select(getProjectRooms)).subscribe(projectRooms => {
      if (projectRooms) {
        this.projectRooms = projectRooms.filter(pr => pr.project === this.project.id && !pr.parentRoom);
        this.subRooms = projectRooms.filter(pr => pr.project === this.project.id && pr.parentRoom);

        this.projectRooms = this.projectRooms.sort(function (a, b) {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.projectRooms = this.projectRooms.sort(function (a, b) {
          const textA = a.floor;
          const textB = b.floor;
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        this.dataSource = new MatTableDataSource<ProjectRoom>(this.projectRooms);

        this.rbc9Sum = 0;
        this.totalSqm = 0;
        this.projectRooms.forEach(p => {
          this.rbc9Sum += +p.workWithAdditions;
          this.totalSqm += +p.size;
        });

        setTimeout(() => this.clearForm(), 2000);
      }
    });

    this.store.pipe(select(getBuildingTypes)).subscribe(buildingTypes => {
      if (buildingTypes) {
        this.buildingTypes = buildingTypes;
      }
    });

    this.store.pipe(select(getProjectRoomTypes)).subscribe(projectRoomTypes => {
      if (projectRoomTypes && projectRoomTypes.length > 0) {
        this.projectRoomTypes = projectRoomTypes.filter(t => t.name.toLowerCase().indexOf('wellness') === -1 && t.name.toLowerCase().indexOf('fußboden') === -1);
        this.projectRoomForm.get('roomType').setValue(this.projectRoomTypes[0].id);
      }
    });

    this.store.pipe(select(getInframeshMountingStyles)).subscribe(inframeshMountingStyles => {
      if (inframeshMountingStyles && inframeshMountingStyles.length > 0) {
        this.inframeshMountingStyles = inframeshMountingStyles;
        this.projectRoomForm.get('mountingStyle').setValue(inframeshMountingStyles[0].id);
      }
    });

    this.store.dispatch(new BuildingTypesLoad());
    this.store.dispatch(new ProjectRoomTypesLoad());
    this.store.dispatch(new InframeshMountingStylesLoad());
    this.store.dispatch(new ProjectRoomLoad());
  }

  offerOverlay(): void {
    const dialogRef = this.dialog.open(OfferOverlayComponent, {
      data: {
        project: this.project.id,
        offerIntro: this.partner.offerIntro,
        offerTerms: this.partner.offerTerms
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(new OffersForProjectLoad(this.project.id));
    });
  }

  buildForm(project: Project | null) {
    this.projectForm = this.formBuilder.group({
      name: [project ? project.name : '', Validators.required],
      street: [project ? project.street : ''],
      zipCode: [project ? project.zipCode : ''],
      city: [project ? project.city : ''],
      country: [{value: 'Österreich', disabled: false}],
      altitude: [project ? project.altitude : 0],
      buildingType: [project ? project.buildingType : null],
      hasOwnHeizlast: [project ? project.hasOwnHeizlast : false],
        energycost: [project ? project.energycost : 0],
        heatingdays: [project ? project.heatingdays : 0],
        heatinghours: [project ? project.heatinghours : 0],
        rabatt: [project ? project.rabatt : 0],
        skipHeizkosten: [project ? project.skipHeizkosten : 0]

    });
  }

  updateProject() {
    this.project.buildingType = this.projectForm.get('buildingType').value;
    this.project.name = this.projectForm.get('name').value;
    this.project.street = this.projectForm.get('street').value;
    this.project.zipCode = this.projectForm.get('zipCode').value;
    this.project.city = this.projectForm.get('city').value;
    this.project.country = this.projectForm.get('country').value;
    this.project.altitude = this.projectForm.get('altitude').value;
    this.project.hasOwnHeizlast = this.projectForm.get('hasOwnHeizlast').value;

      this.project.energycost = this.projectForm.get('energycost').value;
      this.project.heatingdays = this.projectForm.get('heatingdays').value;
      this.project.heatinghours = this.projectForm.get('heatinghours').value;
      this.project.rabatt = this.projectForm.get('rabatt').value;
      this.project.skipHeizkosten = this.projectForm.get('skipHeizkosten').value;



    this.store.dispatch(new ProjectUpdate(this.project));
    this.editProject = false;
    this.store.dispatch(new OffersForProjectLoad(this.project.id));
    this.store.dispatch(new ProjectRoomLoad());

  }


  getBuildingType(id: string) {
    return this.buildingTypes.length > 0 ? this.buildingTypes.find(bt => bt.id === id).name : '';
  }

  getRoomType(id: string) {
    return this.projectRoomTypes.find(rt => rt.id === id).name;
  }

  testOffer() {
    window.open(environment.baseUrl + '/offer/' + this.id, '_blank');
  }

  createProjectRoom() {
    if (this.isCreate) {
      const projectRoom = new ProjectRoom();
      projectRoom.project = this.id;
      projectRoom.name = this.projectRoomForm.get('name').value;
      projectRoom.roomType = this.projectRoomForm.get('roomType').value;
      projectRoom.floor = this.projectRoomForm.get('floor').value;
      projectRoom.size = this.projectRoomForm.get('size').value;
      projectRoom.height = this.projectRoomForm.get('height').value;
      projectRoom.numberWindows = this.projectRoomForm.get('numberWindows').value;
      projectRoom.windowsInsulated = this.projectRoomForm.get('windowsInsulated').value;
      projectRoom.subFloorUnheated = this.projectRoomForm.get('subFloorUnheated').value;
      projectRoom.supFloorUnheated = this.projectRoomForm.get('supFloorUnheated').value;
      projectRoom.plasterInsteadCeiling = this.projectRoomForm.get('plasterInsteadCeiling').value;
      projectRoom.numberOuterWalls = this.projectRoomForm.get('numberOuterWalls').value;
      projectRoom.numberDoorsInside = this.projectRoomForm.get('numberDoorsInside').value;
      projectRoom.mountingStyle = this.projectRoomForm.get('mountingStyle').value;
      projectRoom.workByClient = this.projectRoomForm.get('workByClient').value;


      if (this.projectRoomForm.get('hasWellness').value) {
        projectRoom.subRoomCreate = 'wellness:' + this.projectRoomForm.get('wellnessSize').value + ':' + this.projectRoomForm.get('wellnessMountingStyle').value + ';';
      } else {
        projectRoom.subRoomCreate = 'w;';
      }

      if (this.projectRoomForm.get('hasFussboden').value) {
        projectRoom.subRoomCreate += 'fussboden:' + this.projectRoomForm.get('fussbodenSize').value + ':' + this.projectRoomForm.get('fussbodenMountingStyle').value;
      } else {
        projectRoom.subRoomCreate += 'f';
      }

      this.store.dispatch(new ProjectRoomCreate(projectRoom));


    } else {

      this.selectedRoom.name = this.projectRoomForm.get('name').value;
      this.selectedRoom.roomType = this.projectRoomForm.get('roomType').value;
      this.selectedRoom.floor = this.projectRoomForm.get('floor').value;
      this.selectedRoom.size = this.projectRoomForm.get('size').value;
      this.selectedRoom.height = this.projectRoomForm.get('height').value;
      this.selectedRoom.numberWindows = this.projectRoomForm.get('numberWindows').value;
      this.selectedRoom.windowsInsulated = this.projectRoomForm.get('windowsInsulated').value;
      this.selectedRoom.subFloorUnheated = this.projectRoomForm.get('subFloorUnheated').value;
      this.selectedRoom.supFloorUnheated = this.projectRoomForm.get('supFloorUnheated').value;
      this.selectedRoom.plasterInsteadCeiling = this.projectRoomForm.get('plasterInsteadCeiling').value;
      this.selectedRoom.numberOuterWalls = this.projectRoomForm.get('numberOuterWalls').value;
      this.selectedRoom.numberDoorsInside = this.projectRoomForm.get('numberDoorsInside').value;
      this.selectedRoom.mountingStyle = this.projectRoomForm.get('mountingStyle').value;
      this.selectedRoom.workByClient = this.projectRoomForm.get('workByClient').value;

      if (this.projectRoomForm.get('hasWellness').value) {
        this.selectedRoom.subRoomCreate = 'wellness:' + this.projectRoomForm.get('wellnessSize').value + ':' + this.projectRoomForm.get('wellnessMountingStyle').value + ';';
      } else {
        this.selectedRoom.subRoomCreate = 'w;';
      }

      if (this.projectRoomForm.get('hasFussboden').value) {
        this.selectedRoom.subRoomCreate += 'fussboden:' + this.projectRoomForm.get('fussbodenSize').value + ':' + this.projectRoomForm.get('fussbodenMountingStyle').value;
      } else {
        this.selectedRoom.subRoomCreate += 'f';
      }

      this.store.dispatch(new ProjectRoomUpdate(this.selectedRoom));
    }

    this.store.dispatch(new OffersForProjectLoad(this.project.id));

    setTimeout(() => this.store.dispatch(new ProjectRoomLoad()), 500);

    this.clearForm();
  }

  clearForm() {
    this.showCreateProjectRoom = false;

    let lastRoom;

    if (this.projectRooms.length > 0) {
      lastRoom = this.projectRooms.sort(function (a, b) {
        const textA = new Date(a.created_at);
        const textB = new Date(b.created_at);
        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      })[0];
    }

    this.projectRoomForm = this.formBuilder.group({
      name: ['', Validators.required],
      roomType: [this.projectRoomTypes[0].id],
      floor: [lastRoom ? lastRoom.floor : 0],
      size: [1, Validators.min(0.3)],
      height: [lastRoom ? Number(lastRoom.height).toFixed(2) : 0.5, [Validators.min(0.5), Validators.max(4)]],
      numberWindows: [0, Validators.min(0)],
      windowsInsulated: [true],
      subFloorUnheated: [true],
      supFloorUnheated: [true],
      plasterInsteadCeiling: [lastRoom ? lastRoom.plasterInsteadCeiling : false],
      numberOuterWalls: [0, [Validators.min(0), Validators.max(4)]],
      numberDoorsInside: [0, Validators.min(0)],
      mountingStyle: [lastRoom ? lastRoom.mountingStyle : this.inframeshMountingStyles[0].id],
      workByClient: [0, Validators.min(0)],
      hasWellness: [false],
      wellnessSize: [0, Validators.min(0)],
      wellnessMountingStyle: [this.inframeshMountingStyles[0].id],
      hasFussboden: [false],
      fussbodenSize: [0, Validators.min(0)],
      fussbodenMountingStyle: [this.inframeshMountingStyles[0].id]
    });
  }


  deleteProjectRoom(projectRoom: ProjectRoom) {
    const dialogRef = this.dialog.open(ProjectRoomDeleteConfirmComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(new ProjectRoomDelete(projectRoom.id));
      this.store.dispatch(new OffersForProjectLoad(this.project.id));
      this.store.dispatch(new ProjectRoomLoad());
    });
  }

  updateProjectRoom(projectRoom: ProjectRoom) {
    const wellness = this.subRooms.find(r => r.parentRoom === projectRoom.id && r.name.toLowerCase().indexOf('wellness') !== -1);
    const fussboden = this.subRooms.find(r => r.parentRoom === projectRoom.id && r.name.toLowerCase().indexOf('fussboden') !== -1);

    this.isCreate = false;
    this.selectedRoom = projectRoom;
    this.projectRoomForm = this.formBuilder.group({
      name: [projectRoom.name, Validators.required],
      roomType: [projectRoom.roomType],
      floor: [projectRoom.floor],
      size: [Number(projectRoom.size).toFixed(2)],
      height: [Number(projectRoom.height).toFixed(2)],
      numberWindows: [projectRoom.numberWindows],
      windowsInsulated: [projectRoom.windowsInsulated],
      subFloorUnheated: [projectRoom.subFloorUnheated],
      supFloorUnheated: [projectRoom.supFloorUnheated],
      plasterInsteadCeiling: [projectRoom.plasterInsteadCeiling],
      numberOuterWalls: [projectRoom.numberOuterWalls],
      numberDoorsInside: [projectRoom.numberDoorsInside],
      mountingStyle: [projectRoom.mountingStyle],
      workByClient: [projectRoom.workByClient],
      hasWellness: [wellness !== undefined],
      wellnessSize: [wellness !== undefined ? Number(wellness.size).toFixed(2) : 0, Validators.min(0)],
      wellnessMountingStyle: [wellness !== undefined ? wellness.mountingStyle : this.inframeshMountingStyles[0].id],
      hasFussboden: [fussboden !== undefined],
      fussbodenSize: [fussboden !== undefined ? Number(fussboden.size).toFixed(2) : 0, Validators.min(0)],
      fussbodenMountingStyle: [fussboden !== undefined ? fussboden.mountingStyle : this.inframeshMountingStyles[0].id]
    });


    this.showCreateProjectRoom = true;
  }

}
